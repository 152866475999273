/**
 * Note: this file only serves as entry point and imports all required functionality from modules.
 * These modules are responsible to check whether they are applicable on the current site and only then load their business logic.
 * This way, we can have the best of both worlds, tree-shaking and dynamic imports.
 *
 * When writing modules, make sure to 'register' them in the corresponding index.js and reference them only over this file.
 *
 * If a module is completely not used on a project, comment the corresponding function calls here.
 */

// breakpoint on which headroom should be active
// NOTE: has to match breakpoint in resources/components/patterns/molecules/headroom/_headroom.scss
const breakpoint = 'small',
    videoIntro = document.querySelector('.video-wrapper');

import * as application from './application';

// ////////////////////////////////////////////////////////////
// run bugsnag as early as possible
application.bugsnag();

// ////////////////////////////////////////////////////////////
// check if cookies are enabled
// - add the .no-cookies class to <html /> if not
let cookieEnabled = Boolean(navigator.cookieEnabled);

if (typeof navigator.cookieEnabled === 'undefined' && !cookieEnabled) {
    document.cookie = 'testcookie';
    cookieEnabled = document.cookie.indexOf('testcookie') !== -1;
}

if (!cookieEnabled) {
    document.documentElement.classList.add('no-cookies');
}

// ////////////////////////////////////////////////////////////
// include critical polyfills very early
import * as polyfills from '@spinnwerk/polyfills';
polyfills.focusOptions();

// IE11 polyfills
// polyfills.dom();
// polyfills.fetch();
// polyfills.objectFit();
// polyfills.picturefill();
// polyfills.intersectionObserver();

// ////////////////////////////////////////////////////////////
// image lazy loading
import(/* webpackMode: "lazy" */ 'lazysizes');

// enable support for native lazy loading
/* note: native lazy loading has two major downsides:
 * it loads images much earlier than lazysizes when scrolling down and has no possibility to adjust this value
 * and it loads *all* images above or near the 'fold' regardless of their current visibility state (e.g. in sliders/accordions etc).
 * See https://web.dev/native-lazy-loading#how-does-the-loading-attribute-work-with-images-that-are-in-the-viewport-but-not-immediately-visible-(for-example-behind-a-carousel)
 * If your project is fine with this, feel free to enable the following code and remove the above import:
// import lazySizes from 'lazysizes';
// import 'lazysizes/plugins/native-loading/ls.native-loading';
// lazySizes.cfg.nativeLoading = {
//     setLoadingAttribute: true,
//     disableListeners: true, // remove all event listeners added by lazysizes in browsers that support native lazy loading
// };
 */

// ////////////////////////////////////////////////////////////
// Framework initialization
import * as foundation from './_foundation';
foundation.init();

// ////////////////////////////////////////////////////////////
// Hack vor having full 100vh support on mobile devices
import { fullHeightOnMobile } from './utils/full-height-on-mobile';
fullHeightOnMobile();

// ////////////////////////////////////////////////////////////
// Adds custom classes based on device orientation
import { deviceOrientation } from './utils/device-orientation';
deviceOrientation();

// ////////////////////////////////////////////////////////////
// Security
import { initSecuringExternalAnchors } from './security/external-anchors';
initSecuringExternalAnchors();

// ////////////////////////////////////////////////////////////
// Application setup

// register service worker in production mode
application.serviceWorker();

// optimized font loading
// fonts are defined in app/etc/.fontsrc.json and automatically added to CONFIG
import { loadFonts } from '../../components/base/fonts/fonts';
loadFonts(CONFIG.fonts);
delete CONFIG.fonts; // release memory, fonts are not needed anymore

// ux / accessibility features
application.showFocusOnTabKey();

// ////////////////////////////////////////////////////////////
// Set video source dynamically for each breakpoint
// eslint-disable-next-line one-var
let video = $('.video-wrapper video'),
    // eslint-disable-next-line no-unused-vars
    windowWidth = window.innerWidth;

function appendVideoSource(breakpoint) {
    return "<source src='/videos/mp4/danube-video-" + breakpoint + '.mp4' + "' type='video/mp4'>";
}

if (video) {
    if (windowWidth >= 450) {
        video.append(appendVideoSource('desktop'));
    } else {
        video.append(appendVideoSource('mobile'));
    }
    // if (windowWidth >= 1024) {
    // }
    // else if (windowWidth >= 800) {
    //     video.append(appendVideoSource('tablet'));
    // } else if (windowWidth >= 640) {
    //     video.append(appendVideoSource('medium'));
    // } else if (windowWidth >= 450) {
    //     video.append(appendVideoSource('mobile'));
    // } else {
    //     video.append(appendVideoSource('tiny'));
    // }
}

// init all scrolling belonging
application.autoScrolling({
    fromHashChanges: CONFIG.scrollFromHashChanges,
    offset: {
        small: 100,
        [breakpoint]: {
            up: '.headroom',
            down: '.headroom',
        },
    },
    getTargetId(hash) {
        return hash.replace(/#\/?/, '');
    },
    beforeScroll(target, $target) {
        /* eslint-disable jquery/no-is, jquery/no-closest */
        // jQuery is required to open targeted Accordion-items anyways, so it is fine to use it */
        if ($target.is('[data-tab-content]')) {
            $target.closest('[data-accordion]').foundation('down', $target);
        }
        /* eslint-enable */
    },
});

// hide elements when others appear
application.clearAway({
    target: '.section-nav',
    trigger: '.footer',
});

// ////////////////////////////////////////////////////////////
// Navigation

// update URL and state links based on scrolling
import { sectionNav } from '../../components/patterns/molecules/navigation';
sectionNav({
    getHashFromElement(el) {
        return '/' + (el.dataset.sectionNavAnchor || el.id);
    },
    getActiveStateSelector(hash) {
        return `[href="#${hash.replace(/^#?\//, '')}"]`;
    },
});

// ////////////////////////////////////////////////////////////
// form helpers
import * as form from '../../components/patterns/molecules/form';

function initFormHelpers() {
    // init floating-label-inputs
    form.floatingLabel();

    // enable validation
    form.validation().then(() => {
        form.steps(); // validation needs to be in place before multi-step forms can be initialized

        // automatically attach a loading indicator to submit buttons on form submit
        // but make sure, the validation is attached before
        form.loadingIndicator();
    });

    // enable automatic summaries
    form.summaries();

    // enable conditional inputs
    form.conditionals();

    // enable (de-)selecting all checkboxes at once
    form.selectAll();

    // style advanced file inputs
    form.fileInput({
        multipleFilesLabel: ':anz Dateien ausgewählt',
    });

    // enable multi file uploads
    form.multiUpload();

    // manage hierarchical checkboxes
    form.hierarchicalCheckboxes();
}

initFormHelpers();

// WooCommerce replaces DOM completely, so all functionality must be applied again (-> missing event handlers)
document.body.addEventListener(
    'updated_wc_div',
    () => initFormHelpers(),
    polyfills.eventListenerOptions({ passive: true }),
);

// ////////////////////////////////////////////////////////////
// atoms
import { animateSVG } from '../../components/patterns/atoms/animate';
animateSVG();

// ////////////////////////////////////////////////////////////
// molecules
import { scrollUp } from '../../components/patterns/molecules/scroll-up/scroll-up';
scrollUp({
    createButton:
        'createScrollUpButton' in CONFIG ? CONFIG.createScrollUpButton : !document.querySelector('.section-nav'),
});

import { initHeadroom } from '../../components/patterns/molecules/headroom';
application.globalState.set(
    'headroom',
    initHeadroom({
        // add the spacer only if there is no hero element
        prependSpacerTo: document.querySelector('.wrapper .teaser--hero') ? false : '.off-canvas-content',
        breakpoint,
        offset: videoIntro ? videoIntro.offsetHeight : 0,
    }),
);

import { fontSizeSwitcher } from '../../components/patterns/molecules/font-size-switcher';
fontSizeSwitcher();

import { callout } from '../../components/patterns/molecules/callout/callout';
callout();

import { cards } from '../../components/patterns/molecules/card/cards';
cards();

import { counter } from '../../components/patterns/molecules/counter';
counter();

import { filters } from '../../components/patterns/molecules/filter';
filters();

import { lightbox } from '../../components/patterns/molecules/lightbox';
lightbox();

// ////////////////////////////////////////////////////////////
// layouts
import { masonry } from '../../components/layout/masonry';
masonry();

import { swiper } from '../../components/layout/swiper';
swiper();

import { maps } from '../../components/layout/map';
maps();

// ////////////////////////////////////////////////////////////
// browser alerts
import { oldBrowserAlert } from '../../components/patterns/molecules/browser-alerts';
oldBrowserAlert();

// ////////////////////////////////////////////////////////////
// init web share
import { share } from '../../components/patterns/organisms/share';
share();

// eslint-disable-next-line one-var
let elements = document.querySelectorAll('.text-fade-in'),
    observer = new IntersectionObserver(
        (entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('slide-left');
                }
            });
        },
        { rootMargin: '0px 0px -200px 0px' },
    );

elements.forEach((element) => {
    element.style.opacity = 0;
    observer.observe(element);
});

// eslint-disable-next-line one-var
let mobileReadMoreTriggers = document.querySelectorAll('[data-read-more]');

mobileReadMoreTriggers.forEach((trigger) => {
    trigger.textContent = trigger.dataset.active;
    trigger.addEventListener('click', () => {
        let content = trigger.previousElementSibling.querySelector('.extra-content');

        content.classList.toggle('show-extra-content');

        trigger.innerHTML = content.classList.contains('show-extra-content')
            ? trigger.dataset.inactive
            : trigger.dataset.active;
    });
});

import ScrollBooster from 'scrollbooster';
// eslint-disable-next-line one-var
let outlooks = document.querySelectorAll('[data-outlook]'),
    horizontalScroll = 1500;

outlooks.forEach((outlook) => {
    let content = outlook.querySelector('[data-outlook] img'),
        sb = new ScrollBooster({
            viewport: outlook,
            content: content,
            scrollMode: 'native',
            direction: 'horizontal',
            bounce: false,

            onClick: (state, event) => {
                if (event.target.classList.contains('icon--arrow-right-thin')) {
                    let currentState = sb.getState();

                    sb.scrollTo({ x: currentState.position.x + 500 });
                }

                if (event.target.classList.contains('icon--arrow-left-thin')) {
                    let currentState = sb.getState();

                    sb.scrollTo({ x: currentState.position.x - 500 });
                }
            },
        });

    sb.scrollTo({ x: horizontalScroll, y: 0 });

    horizontalScroll += 500;
});

window.addEventListener('hashchange', () => {
    if (location.hash === '#content') {
        let el = document.querySelector('.button-down');

        if (el) {
            el.style.opacity = 1;
        }

        window.scrollTo(0, videoIntro ? videoIntro.offsetHeight : 0);
    }
});
