/** @module share */

/**
 * Manages sharing content.
 *
 * @returns {Promise<void>} Resolves immediately if no content needs to be shared, or once the required script loaded.
 */
export async function share() {
    let selector = '[data-open="share-modal"]',
        shareDialogButtons = document.querySelectorAll(selector);

    if (shareDialogButtons && shareDialogButtons.length) {
        let { initShare } = await import(/* webpackMode: "lazy" */ './share.async.js');

        await initShare(shareDialogButtons, selector);

        // show the share-button once everything is loaded and in place
        shareDialogButtons.forEach((element) => {
            let shareContainer = element.closest('.share');

            if (shareContainer) {
                shareContainer.hidden = false;
            }
        });
    }
}
